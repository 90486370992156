import { Link } from "react-router-dom";

const Knowledge = () => {
  return (
    <>
      <div className="know" id="know">
        <h2>Knowledge Base</h2>
        <div className="base">
          <div className="base-img">
            <img src="/images/wagon-drill.jfif" alt="bio" id="bio" />
          </div>
          <div className="base-text">
            <h2>Ever heard about Us?</h2>
            <p>
              • Yovan Trading (Pvt) Ltd ZW is an indigenous owned company
              established in 2007. • Yovan Trading (Pvt) Ltd SA is an
              independent company under the ownership of Yovan Trading (Pvt) Ltd
              ZW established in 2015 as a dedicated buying house primarily
              concerned with supply chain management of its parent company
            </p>
            <button>
              <Link
                to="/about"
                style={{
                  textDecoration: "none",
                  paddingTop: "15px",
                  paddingBottom: "15px",
                  paddingLeft: "22px",
                  paddingRight: "22px",
                  color: "white",
                  position: "relative",
                  marginTop: "10px",
                }}
              >
                About us
              </Link>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Knowledge;
