import NavBar from "../components/nav";
import ContructorDrilling from "../components/contractor_drilling";
import About from "../components/footer";
const CoDrilling = () => {
  return (
    <>
      <NavBar />
      <ContructorDrilling />
      <About />
    </>
  );
};

export default CoDrilling;
