export const contructor_images = [
  {
    id: "1",
    img: "images/220-Jackleg.jpg",
    text: "220-Jackleg",
  },
  {
    id: "2",
    img: "images/3600W-jack-hammer.jpg",
    text: "3600W Jack Hammer",
  },
  {
    id: "3",
    img: "images/BBC120F Drifter.jpg",
    text: "BBC120F Drifter",
  },
  {
    id: "4",
    img: "images/bbc120F.jpg",
    text: "Bbc120 Filter",
  },
  {
    id: "5",
    img: "images/Clarke contractor CON150RDV 1500W.jpg",
    text: "Clarke Contractor CON150RDV 1500W",
  },
  {
    id: "6",
    img: "images/hydrolic-motor-inm-series.jpg",
    text: "Dynamic Hydrolic Motor INM series",
  },
  {
    id: "7",
    img: "images/mine shaft.jpg",
    text: "Mine Shaft",
  },
  {
    id: "9",
    img: "images/PHQ Rotation drill.jpg",
    text: "PHQ Rotation drill",
  },
  {
    id: "10",
    img: "images/PHQ15001H Heavy Duty Pneumatic Centralizer.jpg",
    text: "Pneumatic Centralizer",
  },
  {
    id: "11",
    img: "images/PMK_634-1 stabilizer hammer.jpg",
    text: "DTH Stabilizers",
  },
  {
    id: "12",
    img: "images/roller-crusher.jpg",
    text: "Roller Crusher",
  },
  {
    id: "13",
    img: "images/Rotary-Scrubber.jpg",
    text: "Rotary-Scrubber",
  },
];
