import Slider from "../components/slider";
import NavBar from "../components/nav";
import Products from "../components/products";
import Knowledge from "../components/knowledge";
import About from "../components/footer";

const Home = () => {
  return (
    <>
      <NavBar />
      <Slider />
      <Products />
      <br />
      <br />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "30px",
          flexWrap: "wrap",
        }}
      >
        <img src="./images/dt2.png" alt="" height="auto" width="90%" />
        <img src="./images/dt.png" alt="" height="auto" width="90%" />
      </div>
      <Knowledge />
      <About />
    </>
  );
};

export default Home;
