import { useState } from "react";
import { Link } from "react-router-dom";
const NavBar = () => {
  const [nav, setNav] = useState(false);
  const [prev] = useState(true);
  const [show, setShow] = useState(false);
  const [hide, setHide] = useState(false);
  const doDisplay = (e) => {
    setShow(!show);
  };

  const doHide = () => {
    setHide(!hide);
  };

  return (
    <>
      {prev && (
        <>
          <div className="achors">
            <a href="/" style={{ display: "block" }}>
              <div className="pres">
                <div className="logo-control">
                  <img src="/images/yovan-logo.png" alt="" id="logo" />
                </div>
                <h3>Yovan Trading (PVT) LTD</h3>
              </div>
            </a>
            <div>
              <nav>
                <a href="/">Home</a>
                <a href="#" id="prod-nav-one" onClick={() => doDisplay()}>
                  Products
                  <img src="/images/arrow-down.png" alt="" id="down" />
                  {show && (
                    <>
                      <div className="quick">
                        <Link to="/mining-equipment">Underground Drilling</Link>
                        <Link to="/industrial-equipment">Surface drilling</Link>
                        <Link to="/contractor-drilling">
                          Contractor Drilling
                        </Link>
                        <Link to="/mining-assessories">Accessories</Link>
                      </div>
                    </>
                  )}
                </a>
                <Link to="/about">About Us</Link>
                <a href="#contact">Contact Us</a>
              </nav>
            </div>
            <span onClick={() => setNav(!nav)}>&#9776;</span>
          </div>
        </>
      )}

      {nav && (
        <>
          <div>
            <div className="menu">
              <nav>
                <a href="/">Home</a>
                <a href="#" id="prod-nav-one" onClick={() => doHide()}>
                  Products
                  <img src="/images/arrow-down.png" alt="" />
                  {hide && (
                    <>
                      <Link to="/mining-equipment">Underground Drilling</Link>
                      <Link to="/industrial-equipment">Surface drilling</Link>
                      <Link to="/contractor-drilling">Contractor Drilling</Link>
                      <Link to="/mining-assessories">Accessories</Link>
                    </>
                  )}
                </a>
                <Link to="/about">About Us</Link>
                <a href="#contact">Contact Us</a>
              </nav>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default NavBar;
